import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Flex, Title, Label, Box } from "../components/system"

const FAQ = ({ data }) => {
  return (
    <Layout dark>
      <Flex flexDirection="column" pt={6}>
        <Label fontSize={1} color="faded">
          FAQ
        </Label>
        <Title fontSize={[6, 7]} color="secondary">
          Questions & Answers
        </Title>
        <Box my={5} px={[4, 7]} width="100%">
          {data.sanityFaq.answers.map((q, key) => (
            <Box key={key} mb={4}>
              <Title color="black" textAlign="left" fontSize={[4, 5]} mb={2}>
                • {q.question}
              </Title>
              <Label down color="shadow">
                {q.answer}
              </Label>
            </Box>
          ))}
        </Box>
      </Flex>
    </Layout>
  )
}

export default FAQ

export const pageQuery = graphql`
  query faq {
    sanityFaq(_id: { eq: "faq" }) {
      answers {
        question
        answer
      }
    }
  }
`
